/*CUSTOM FONT*/

body {
  font-family: 'open_sansregular',Helvetica,Arial,sans-serif !important;
  padding-top: 50px;
    padding-bottom: 20px;
}

html, body {
    height: 100%;
}


.img-home-portfolio,
.img-customer,
.portfolio-item {
    margin-bottom: 30px;
}

.tab-pane {
    margin-top: 15px;
}

/* Page Sections */
.section,
.section-colored {
    padding: 50px 0;
}

.section-colored {
    background-color: #e1e1e1;
    /* change this to change the background color of a colored section */
}

.sidebar {
    margin-top: 40px;
}

.sidebar ul {
    border-radius: 5px;
    padding: 5px;
    border: 1px solid #cccccc;
}

/* Half Page Height Carousel Customization */
.carousel {
    height: 50%;
}

.item,
.active,
.carousel-inner {
    height: 100%;
}

.fill {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
}

/* Social Icons Styles */
.list-social-icons {
    margin-bottom: 45px;
}

.tooltip-social a {
    text-decoration: none;
    color: inherit;
}

.facebook-link a:hover {
    color: #3b5998;
}

.linkedin-link a:hover {
    color: #007fb1;
}

.twitter-link a:hover {
    color: #39a9e0;
}

.google-plus-link a:hover {
    color: #d14836;
}

/* Service Page Styles */
.service-icon {
    font-size: 50px;
}

/* 404 Page Styles */
.error-404 {
    font-size: 8em;
}

/* Pricing Page Styles */
.price {
    font-size: 4em;
}

.price-cents {
    vertical-align: super;
    font-size: 50%;
}

.price-month {
    font-size: 35%;
    font-style: italic;
}

/* Footer Styles */
footer {
    margin: 50px 0;
}

/* Responsive Styles */
@media (max-width: 767px) {

    .carousel {
        height: 70%;
        /* increases the carousel height so it looks good on phones */
    }
}


.carousel-indicators {
  bottom:-50px;
}

.carousel-indicators li {
  border-color:#999;
  background-color:#ccc;
}

.carousel-inner {
   margin-bottom:50px;
}
.large_bg_black {
		font-size:25px;
		line-height:30px;
		font-weight:700;
		font-family:"Open Sans";
		color:rgb(255, 255, 255);
		text-decoration:none;
		background-color:#008cba;
		padding:5px 8px 4px;
		border-width:0px;
		border: none;
		border-style:none;
}
.price {
	font-size: 1.8em;
}

.price-cents {
	vertical-align: super;
	font-size: 50%;
}

.price-month {
	font-size: 40%;
	font-style: italic;
	color:#008cba;
}
.panel {
	-webkit-transition-property: scale;
	-webkit-transition-duration: 0.2s;
	-webkit-transition-timing-function: ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
}

.panel:hover {
	box-shadow: 0 0 10px rgba(0,0,0,.5);
	-moz-box-shadow: 0 0 10px rgba(0,0,0,.5);
	-webkit-box-shadow: 0 0 10px rgba(0,0,0,.5);
	-webkit-transform: scale(1.05);
	-moz-transform: scale(1.05);
}



 /* Set the fixed height of the footer here */
  #footer {
    height: 58px;
    line-height:58px;
    background-color: #008cba;
    color: #dddddd;
    text-align: center;
  }
   #footer a{
    color: #dddddd;
    text-decoration: none;
  }
  #footer a:hover{
    color: #eeeeee;
    text-decoration: none;
  }
  #footer {
  /* Lastly, apply responsive CSS fixes as necessary */
  @media (max-width: 767px) {
    #footer {
      margin-left: -20px;
      margin-right: -20px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
